import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {Invoice} from '../models/Invoice';
import {InvoiceNew} from '../models/InvoiceNew';

@Injectable({
  providedIn: 'root'
})
export class InvoiceListService extends BaseServiceService{

  getFaturas(): Observable<Array<InvoiceNew>> {
    return this.get('client_area/invoice_new');
  }
}
