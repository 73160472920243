import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialNewComponent } from './financial-new.component';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {BrowserModule} from '@angular/platform-browser';
import {MatCheckboxModule} from '@angular/material/checkbox';



@NgModule({
  declarations: [
      FinancialNewComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    MatButtonModule,
    BrowserModule,
    MatCheckboxModule,
  ],
  exports: [
      // FinancialNewComponent,
  ]
})
export class FinancialNewModule { }
