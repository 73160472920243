<h1 mat-dialog-title>Desejo pagar com:</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <mat-tab-group>
        <mat-tab label="Link de pagamento" *ngIf="true">
          <div class="row">
            <div class="col-12 botoes-boleto text-center">
              <button mat-button mat-raised-button color="primary" (click)="redirecionarGateway()">Prosseguir para o Gateway de Pagamento</button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Cartão de crédito" *ngIf="false">
          <form [formGroup]="formCartao" class="mt-3" style="overflow-y: hidden">
            <div class="row">
              <div class="col-xs-12 col-md-10">
                <h2>Dados do cartão</h2>
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <mat-form-field>
                      <mat-label>Número do cartão</mat-label>
                      <input type="text" matInput mask="0000 0000 0000 0000 || 0000 000000 00000" formControlName="numeroCartao">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <mat-form-field>
                      <mat-label>Nome impresso no cartão</mat-label>
                      <input type="text" matInput formControlName="nomeCartao">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>Vencimento</mat-label>
                      <input type="text" matInput mask="00/0000" formControlName="vencimento">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <mat-form-field>
                      <mat-label>CCV</mat-label>
                      <input type="text" matInput mask="000 || 0000" formControlName="cvv">
                    </mat-form-field>
                  </div>
                </div>
                <h2>Endereço de cobrança</h2>
                <div class="row">
                  <div class="col-xs-12 col-md-3">
                    <mat-form-field>
                      <mat-label>CEP</mat-label>
                      <input type="text" matInput mask="00000-000" formControlName="cep" (blur)="preencherEndereco()">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-6">
                    <mat-form-field>
                      <mat-label>Logradouro</mat-label>
                      <input type="text" matInput formControlName="logradouro">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <mat-form-field>
                      <mat-label>Número</mat-label>
                      <input type="text" matInput formControlName="numero">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>Complemento</mat-label>
                      <input type="text" matInput formControlName="complemento">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>Bairro</mat-label>
                      <input type="text" matInput formControlName="bairro">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>Cidade</mat-label>
                      <input type="text" matInput formControlName="cidade">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>Estado</mat-label>
                      <mat-select formControlName="estado">
                        <mat-option value="">Selecione...</mat-option>
                        <mat-option value="AC">Acre</mat-option>
                        <mat-option value="AL">Alagoas</mat-option>
                        <mat-option value="AP">Amapá</mat-option>
                        <mat-option value="AM">Amazonas</mat-option>
                        <mat-option value="BA">Bahia</mat-option>
                        <mat-option value="CE">Ceará</mat-option>
                        <mat-option value="DF">Distrito Federal</mat-option>
                        <mat-option value="ES">Espírito Santo</mat-option>
                        <mat-option value="GO">Goiás</mat-option>
                        <mat-option value="MA">Maranhão</mat-option>
                        <mat-option value="MT">Mato Grosso</mat-option>
                        <mat-option value="MS">Mato Grosso do Sul</mat-option>
                        <mat-option value="MG">Minas Gerais</mat-option>
                        <mat-option value="PA">Pará</mat-option>
                        <mat-option value="PB">Paraíba</mat-option>
                        <mat-option value="PR">Paraná</mat-option>
                        <mat-option value="PE">Pernambuco</mat-option>
                        <mat-option value="PI">Piauí</mat-option>
                        <mat-option value="RJ">Rio de Janeiro</mat-option>
                        <mat-option value="RN">Rio Grande do Norte</mat-option>
                        <mat-option value="RS">Rio Grande do Sul</mat-option>
                        <mat-option value="RO">Rondônia</mat-option>
                        <mat-option value="RR">Roraima</mat-option>
                        <mat-option value="SC">Santa Catarina</mat-option>
                        <mat-option value="SP">São Paulo</mat-option>
                        <mat-option value="SE">Sergipe</mat-option>
                        <mat-option value="TO">Tocantins</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <h2>Informações de contato</h2>
                <div class="row">
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>Celular</mat-label>
                      <input type="text" matInput formControlName="celular" mask="(00) 0000-0000 || (00) 00000-0000">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>E-mail</mat-label>
                      <input type="email" matInput formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <mat-form-field>
                      <mat-label>CPF/CNPJ</mat-label>
                      <input type="text" matInput formControlName="documento" mask="000.000.000-00 || 00.000.000/00000-00">
                    </mat-form-field>
                  </div>
                </div>
<!--                <div class="row">-->
<!--                  <div class="col-12">-->
<!--                    <mat-checkbox value="1" formControlName="cadastrarRecorrencia" #checkboxCadastroCartao>Cadastrar este cartão para futuras cobranças dos meus contratos</mat-checkbox>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row" *ngIf="checkboxCadastroCartao.checked">-->
<!--                  <div class="col-12">-->
<!--                    <div class="alert alert-info">-->
<!--                      <h2>Ao cadastrar este cartão, você concorda com os termos a seguir:</h2>-->
<!--                      <ul>-->
<!--                        <li>Os dados do seu cartão de crédito ficarão armazenados em nosso Gateway de Pagamento. Não temos acesso direto a essa informação.</li>-->
<!--                        <li>No dia do vencimento de cada contrato, será feito um débito para cada contrato que você possuir conosco.</li>-->
<!--                        <li>É possível excluir seu cartão de crédito. Basta ir na opção "Cartões de crédito" no menu lateral.</li>-->
<!--                        <li>Caso a captura do pagamento não seja efetivada no dia do vencimento, serão cobrados juros e multa sobre a fatura. Garanta que seu cartão de crédito esteja ativado e que tenha limite disponível no dia do vencimento.</li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="row">
                  <div class="col-12">
                    <button class="pagar" mat-button mat-raised-button color="primary" [disabled]="formCartao.invalid" (click)="pagarCartaoCredito()">Pagar</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-tab>
        <mat-tab label="Pix">
          <div class="row mt-3">
            <div class="col-12">
              <div class="alert alert-success text-center">
                <strong>Abra o aplicativo do seu banco e escaneie o QR Code abaixo para efetuar o pagamento.</strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <img [src]="'data:image/png;base64,' + checkoutConfigResponse.PixQrCodeResponse?.IMAGEM_QR_CODE" alt="QR Code Pix" class="img-thumbnail">
            </div>
            <div class="col-xs-12 col-md-9">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <h1>Descrição</h1>
                  <span>{{checkoutConfigResponse.DescricaoFatura}}</span>
                </div>
                <div class="col-xs-12 col-md-3">
                  <h1>Vencimento</h1>
                  <span>{{checkoutConfigResponse.PixQrCodeResponse?.DATA_VENCIMENTO | date}}</span>
                </div>
                <div class="col-xs-12 col-md-3">
                  <h1>Valor</h1>
                  <span>{{checkoutConfigResponse.PixQrCodeResponse?.VALOR | currency: 'BRL'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <button mat-button mat-raised-button color="primary" (click)="copiarQrCodePix()">Copiar QR Code</button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Boleto bancário" *ngIf="checkoutConfigResponse.BoletoCheckoutResponse !== null">
          <div class="row mt-3">
            <div class="col-12">
              <div class="alert alert-info text-center">
                <strong>O pagamento só será identificado no próximo dia útil. Dê preferência a outras formas de pagamento caso tenha urgência.</strong>
              </div>
            </div>
          </div>
          <div class="row botoes-boleto">
            <div class="col-xs-12 col-md-4 offset-md-3 text-center">
              <button mat-button mat-raised-button color="primary" (click)="copiarCodigoBarrasBoleto()">Copiar código de barras</button>
            </div>
            <div class="col-xs-12 col-md-4">
              <button mat-button mat-raised-button color="accent" (click)="redirecionarBoleto()">Imprimir</button>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
