import {Component, OnInit} from '@angular/core';
import {FinancialService} from '../financial/financial.service';
import {Contract} from '../models/Contract';
import {Router} from '@angular/router';


@Component({
  selector: 'app-financial-new',
  templateUrl: './financial-new.component.html',
  styleUrls: ['./financial-new.component.css']
})
export class FinancialNewComponent implements OnInit {
  public showComponent = false;
  public contracts: Array<Contract> = [];
  public displayedColumns: string[] = ['SELETOR', 'MES', 'VENCIMENTO', 'VALOR', 'VALOR_SEGURO', 'VALOR_OUTROS', 'VALOR_TOTAL'];
  constructor(
    public financialService: FinancialService,
    public route: Router,
  ) { }

  public movements: Array<number> = [];
  public total = 0;

  ngOnInit(): void{
    this.financialService.showSpinner();
    this.financialService.getMyDebts().subscribe(data => {
      this.contracts = data;
      this.showComponent = true;
      this.financialService.hideSpinner();
    }, err => {
      console.error(err);
      this.financialService.toasterError('Erro');
      this.financialService.hideSpinner();
    });
  }

  public doPayment(id: string): void{
    this.financialService.toasterInfo(id);
  }

  public async appendMovement(id: number, value: string): Promise<any> {
    const index = this.movements.indexOf(id);
    if (index < 0){
      this.movements.push(id);
      this.total = this.total + parseFloat(value);
    } else {
      this.movements.splice(index, 1);
      this.total = this.total - parseFloat(value);
    }
  }

  public createInvoice(): void {
    this.financialService.showSpinner();
    this.financialService.createInvoiceByMoment(this.movements).then(x => {
        this.route.navigate(['principal/pagamento-cartao/' + x.CODIGO_FATURA]);
    }).catch(err => {
        console.error(err);
        this.financialService.toasterError('Houve um erro');
        this.financialService.hideSpinner();
    });
  }
}
