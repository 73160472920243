<div class="div-full">
  <mat-card class="inner">
    <mat-card-title>Recuperação de senha</mat-card-title>
    <mat-card-content>
      <mat-vertical-stepper [linear]="true">
        <mat-step errorMessage="Informe um CPF ou CNPJ" [stepControl]="sendResetEmailForm">
          <ng-template matStepLabel>Informe o CPF ou CNPJ</ng-template>
          <form [formGroup]="sendResetEmailForm">
            <mat-form-field class="full">
              <mat-label>CPF/CNPJ</mat-label>
              <input matInput  placeholder="Informe seu CPF ou CNPJ" type="text" required formControlName="documento" mask="CPF_CNPJ">
            </mat-form-field>
            <p>
              <button matStepperNext class="pull-right" [disabled]="!sendResetEmailForm.valid" mat-stroked-button color="primary" (click)="validateDocumentClick()">Prosseguir</button>
            </p>
          </form>
        </mat-step>
        <mat-step errorMessage="Informe o código de verificação válido" [stepControl]="validateCodeForm">
          <ng-template matStepLabel>Código de verificação</ng-template>
          <form [formGroup]="validateCodeForm">
            <mat-form-field class="full">
              <mat-label>Código de verificação</mat-label>
              <input matInput placeholder="Forneça o código de verificação enviado no seu e-mail cadastrado" type="text" maxlength="6" required formControlName="code">
            </mat-form-field>
            <p>
              <button matStepperNext class="pull-right" [disabled]="!validateCodeForm.valid" mat-stroked-button color="primary" (click)="validateEmailCode()">Prosseguir</button>
            </p>
          </form>
        </mat-step>
        <mat-step errorMessage="Informe o código de verificação válido" [stepControl]="setNewPasswordForm">
          <ng-template matStepLabel>Senha nova</ng-template>
          <form [formGroup]="setNewPasswordForm">
            <mat-form-field class="full">
              <mat-label>Nova senha</mat-label>
              <input matInput  placeholder="Digite a nova senha" type="password" required formControlName="newPassword">
            </mat-form-field>
            <mat-form-field class="full">
              <mat-label>Confirmar digitação</mat-label>
              <input matInput  placeholder="Digite a nova senha novamente" type="password" required formControlName="newPasswordConfirm">
            </mat-form-field>
            <p>
              <button matStepperNext class="pull-right" [disabled]="!validateCodeForm.valid" mat-stroked-button color="primary" (click)="changePassword()">Prosseguir</button>
            </p>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </mat-card-content>
  </mat-card>
</div>
<ngx-spinner></ngx-spinner>
