import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseServiceService } from '../base-service.service';
import { OpenedEntries } from '../models/OpenedEntries';
import {InvoiceNew} from '../models/InvoiceNew';

@Injectable({
  providedIn: 'root'
})
export class OpenedEntriesService extends BaseServiceService {

  public getOpenedEntriesList(userCode: string): Observable<OpenedEntries> {
    return this.get('client_area/opened_entries_list/' + userCode);
  }

  public createFaturaSelected(list: number[]): Observable<InvoiceNew> {
    return this.post('client_area/create_invoice', list);
  }
}
