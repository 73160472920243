import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {Invoice} from '../models/Invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseServiceService{

  public getInvoice(id: number): Observable<Invoice> {
    return this.get('client_area/invoice/' + id);
  }

  public getBankInvoice(id: number): Observable<Blob> {
    const h = this.getHeaders();
    // @ts-ignore
    return this.http.get(this.getUrl(`client_area/render_invoice/${id}?output=2`), {headers: h, responseType: 'blob' as 'json'});
  }

  public getBankInvoiceUrl(id: number, modo: number = 2): string {
    return this.getUrl(`client_area/render_invoice/${id}?output=${modo}`);
  }

  public payInvoiceWithCreditCard(id: string, obj: object): Observable<any> {
    return this.http.post(this.getUrl("client_area/proccess_payment_credit_card/" + id), obj, {headers: this.getHeaders()});
  }
}
