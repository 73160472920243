import { Component, OnInit } from '@angular/core';
import {InvoiceListService} from './invoice-list.service';
import {MatTableDataSource} from '@angular/material/table';
import {Financial} from '../models/Financial';
import {InvoiceNew} from '../models/InvoiceNew';
import {lastValueFrom, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Field, FieldBadge} from "@cdsistemas/ui";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Invoice} from "../models/Invoice";

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  constructor(
      private httpService: InvoiceListService,
      private currencyPipe: CurrencyPipe,
      private datePipe: DatePipe,
  ) { }

  public dataSourceInvoices: MatTableDataSource<InvoiceNew> = new MatTableDataSource<InvoiceNew>();
  public fieldsFatura: Field[] = [
    {
      fieldName: "CODIGO_FATURA",
      primaryKey: true,
      fieldOrder: 1,
      visible: true,
      isBadge: false,
      pipe: value => value,
      canonicalName: "Nº",
      columnClasses: "text-center",
    },
    {
      fieldName: "DESCRICAO_FATURA",
      primaryKey: false,
      fieldOrder: 1,
      visible: true,
      isBadge: false,
      pipe: value => value,
      canonicalName: "Descrição",
      columnClasses: "",
    },
    {
      fieldName: "DATA_VENCIMENTO",
      primaryKey: false,
      fieldOrder: 1,
      visible: true,
      isBadge: false,
      pipe: value => this.datePipe.transform(value),
      canonicalName: "Vencimento",
      columnClasses: "text-center",
    },
    {
      fieldName: "VALOR_TOTAL",
      primaryKey: false,
      fieldOrder: 1,
      visible: true,
      isBadge: false,
      pipe: value => this.currencyPipe.transform(value),
      canonicalName: "Valor total",
      columnClasses: "text-right",
    },
    {
      fieldName: "SITUACAO",
      primaryKey: false,
      fieldOrder: 1,
      visible: true,
      isBadge: true,
      pipe: value => value,
      canonicalName: "Situação",
      columnClasses: "text-center",
      badgeConfig: (value: InvoiceNew) => {
        let ret: FieldBadge[] = [];
        if(value.INADIMPLENTE){
          ret.push({
            text: "Em atraso",
            color: "bg-danger"
          });
        } else if(value.DATA_PAGAMENTO){
          ret.push(
            {
              text: "Pago em " + this.datePipe.transform(value.DATA_PAGAMENTO),
              color: "bg-success",
            }
          )
        } else {
          ret.push({
            text: "Aguardando pagamento",
            color: "bg-info",
          });
        }
        return ret;
      },
    },
  ];

  async ngOnInit(): Promise<any> {
    try {
      this.httpService.showSpinner();
      const invoices = await lastValueFrom(this.httpService.getFaturas());
      this.dataSourceInvoices = new MatTableDataSource<InvoiceNew>(invoices as any);
    } catch (e){
      console.error(e);
      this.httpService.toasterError('Houve um erro ao montar esta tela');
    } finally {
      this.httpService.hideSpinner();
    }
  }
}
