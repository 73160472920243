import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public changePasswordForm: FormGroup = new FormGroup<any>({});
  public codeUser: string | null = localStorage.getItem('CODIGO_CADASTRO_GERAL');

  constructor(
    private service: ChangePasswordService,
    private router: Router
  ) { }

  public changePassword(): void {
    const newPass = this.changePasswordForm.value.newPassword;
    const confirmNewPass = this.changePasswordForm.value.confirmNewPassword;
    this.validatePasswordChange(newPass, confirmNewPass);
  }

  protected validatePasswordChange(newPassword: string, confirmPassword: string): void {
    this.service.showSpinner();
    this.service.setNewPassword(this.codeUser ?? "", newPassword, confirmPassword).subscribe((data) => {
      this.service.hideSpinner();
      this.service.toasterSuccess('Senha alterada com sucesso!');
      this.router.navigate(['principal/dashboard']);
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterWarning(err.error ?? 'Erro ao alterar a senha');
    });
  }

  public validatePasswords(): boolean {
    if (
      (this.changePasswordForm.value.newPassword === this.changePasswordForm.value.confirmNewPassword) &&
      (this.changePasswordForm.value.newPassword !== '') &&
      (this.changePasswordForm.value.confirmNewPassword !== '')
      ) {
      // document.getElementById("hint-senhas").style.display = "block"
      return true;
    } else {
      // document.getElementById("hint-senhas").style.display = "none"
      return false;
    }
  }

  validatePasswordsConfirm(): boolean {
    return this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmNewPassword;
  }

  getErrorMessage(): string {
    return 'As senhas não correspondem';
  }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required]),
      confirmNewPassword: new FormControl('', [Validators.required])
    });
  }

  returnToDashboard(): void {
    this.router.navigate(['principal/dashboard']);
  }

}
