import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PagamentoFaturaService} from './payment-invoice.service';
import {InvoiceNew} from '../models/InvoiceNew';
import {MatDialog} from '@angular/material/dialog';
import {GenericDialogComponent} from '../generic-dialog/generic-dialog.component';
import {GenericDialogData} from '../models/GenericDialogData';
import {DuplicateInvoiceService} from '../duplicate-invoice/duplicate-invoice.service';
import {AppConstants} from '../appConstants';
import {PaymentCheckoutModalComponent} from "../payment-checkout-modal/payment-checkout-modal.component";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-payment-invoice',
  templateUrl: './payment-invoice.component.html',
  styleUrls: ['./payment-invoice.component.css']
})
export class PagamentoFaturaComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private service: PagamentoFaturaService,
      private boletoService: DuplicateInvoiceService,
      public dialog: MatDialog,
  ) {
    this.id = this.route.snapshot.params["id"];
  }

  public invoice: InvoiceNew = {} as InvoiceNew;
  public id: number;
  public constants = AppConstants;

  public form: FormGroup = new FormGroup({
    card_number: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(5)]),
    card_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    expiration_month: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(3)]),
    expiration_year: new FormControl('', [Validators.required]),
    document: new FormControl('', [Validators.required])
  });

  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      const res = await lastValueFrom(this.service.getInvoice(this.id));
      this.invoice = res;
      if (res.DATA_PAGAMENTO != null){
        if  (res.DATA_PROCESSAMENTO == null) {
          this.service.toasterSuccess('Esta fatura já consta como paga.' +
            '\n\n' +
            'Aguarde alguns minutos para a atualização de status do seu contrato e dos controles de acesso ' +
            'ao seu box.', 'Parabéns');
        } else {
          this.service.toasterSuccess('Esta fatura já consta como paga.', 'Parabéns');
        }
      } else if (res.ACRESCIMO > 0){
        this.service.toasterWarning('Esta fatura possui acréscimos por estar em atraso. Faça o pagamento para garantir a continuidade do contrato', 'Aviso');
      }
    } catch (e) {
      this.service.toasterError('Erro ao gerar faturamento');
      await this.returnToDashboard();
    } finally {
      this.service.hideSpinner();
    }
  }
  async returnToDashboard(): Promise<any> {
    await this.router.navigate(['principal/dashboard']);
  }
  async proccessCheckout(paymentType: number): Promise<any>{
    // const dialog = this.dialog.open(GenericDialogComponent, {
    //   maxWidth: '100%',
    //   data: {
    //     isHtmlString: true,
    //     msg: 'Você será redirecionado para o nosso gateway de pagamento para finalizar esta operaçāo.',
    //     msgCloseButton: 'Cancelar',
    //     showCloseButton: true,
    //     msgConfirmButton: 'Concordo',
    //     title: 'Aviso de redirecionamento',
    //   } as GenericDialogData,
    //   autoFocus: true,
    //   role: 'alertdialog',
    // });
    // const data = await lastValueFrom(dialog.afterClosed()) as boolean;
    this.service.showSpinner();
    try {
      const res = await lastValueFrom(this.service.proccessPaymentCheckout(this.id, paymentType));
      this.service.toasterInfo("Verifique se o seu navegador bloqueou o pop-up", "Importante");
      window.open(res.UrlCheckout ?? "", '_blank');

    } catch (err: any) {
      console.error(err);
      this.service.toasterError(err.error, 'Houve um erro no checkout');
    } finally {
      this.service.hideSpinner();
    }
  }

  async buildCheckout(): Promise<any>{
    try {
      this.service.showSpinner();
      let data = await lastValueFrom(this.service.proccessPaymentCheckout(this.id, 0));
      data.DescricaoFatura = this.invoice.DESCRICAO_FATURA;
      await lastValueFrom(this.dialog.open(PaymentCheckoutModalComponent, {
          width: "100%",
          minHeight: "500px",
          data: data,
        }).afterOpened()
      );
    } catch (e: any){
      console.log(e);
      this.service.toasterError(e.error)
    } finally {
      this.service.hideSpinner();
    }

    // const dialog = this.dialog.open(GenericDialogComponent, {
    //   // maxWidth: '60%',
    //   data: {
    //     isHtmlString: true,
    //     msg: 'Você será redirecionado para o nosso gateway de pagamento para finalizar esta operaçāo.',
    //     msgCloseButton: 'Cancelar',
    //     showCloseButton: true,
    //     msgConfirmButton: 'Concordo',
    //     title: 'Aviso de redirecionamento',
    //   } as GenericDialogData,
    //   autoFocus: true,
    //   role: 'alertdialog',
    // });
    //
    // dialog.afterClosed().subscribe((data) => {
    //   if (data){
    //     this.service.showSpinner();
    //     this.service.toasterInfo('Você será redirecionado para a página onde irá concluir esta transaçāo', 'Aguarde');
    //     this.service.proccessPaymentCheckout(this.id, paymentType).subscribe((res) => {
    //       window.open(res[0], '_blank');
    //       this.service.hideSpinner();
    //     }, (err) => {
    //       this.service.hideSpinner();
    //       console.error(err);
    //       this.service.toasterError(err.error, 'Houve um erro no checkout');
    //     });
    //   }
    // });
  }

  disclaimerAcrescimos(): void {
    this.service.toasterInfo('Eventuais juros e multas de lançamentos atrasados', 'Informaçāo');
  }

  showBoleto(): void{
    this.service.toasterInfo('Gerando o seu boleto', 'Aguarde');
    this.service.showSpinner();
    this.boletoService.getBankInvoice(this.invoice.CODIGO_BOLETO).subscribe((data) => {
      this.service.hideSpinner();
      const file = new Blob([data],{ type: 'text/html' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.service.toasterInfo('Caso seu boleto não esteja visível, verifique se o seu navegador bloqueou algum popup para esta página.', 'Dica');
    }, (error) => {
      this.service.hideSpinner();
      console.error(error);
      this.service.toasterError('Erro ao gerar boleto');
    });
  }

  showNfse(): void {

  }

  copyCodigoBarrasBoleto(): void{
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.invoice.CODIGO_BARRAS_BOLETO;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.service.toasterSuccess('Código de barras copiado!', 'Sucesso');
  }
}
