import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import { DashboardService } from '../dashboard/dashboard.service';
import {Contract} from '../models/Contract';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CreditCardRequest} from '../models/CreditCardRequest';
import {CreditCardService} from './credit-card.service';
import {Router} from '@angular/router';
export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.css']
})
export class CreditCardFormComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private creditCardService: CreditCardService,
    private router: Router,
  ) { }
  public contracts: Array<Contract> = [];
  public formContract: FormGroup = new FormGroup({
    GEN_CONTRATO: new FormControl([], [Validators.minLength(1)]),
  });
  public formCreditCard: FormGroup = new FormGroup({
    holderName: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3)]),
    due: new FormControl('', [Validators.required, Validators.minLength(7)]),
  });

  public formBillingCard: FormGroup = new FormGroup({
    billingName: new FormControl('', [Validators.required]),
    billingAddress: new FormControl('', [Validators.required]),
    billingNumber: new FormControl('', [Validators.required]),
    billingComplement: new FormControl('', []),
    billingNeighborhood: new FormControl('', [Validators.required]),
    billingCity: new FormControl('', [Validators.required]),
    billingState: new FormControl('', [Validators.required]),
    billingZipCode: new FormControl('', [Validators.required]),
    billingPhone: new FormControl('', [Validators.required]),
    billingEmail: new FormControl('', [Validators.required]),
    billingCpfCnpj: new FormControl('', [Validators.required])
  });
  public cardIcon = '';

  ngOnInit(): void {
    this.dashboardService.getStatistics().subscribe(data => {
      this.contracts = data.CONTRATOS;
    }, err => {
      console.error(err);
    });
  }


  public getCreditCardFlag(): string {
    let cardNumber = this.formCreditCard.get('number')?.value;
    cardNumber = cardNumber.replace(/\s|-/g, '');
    const cardRegex: { [key: string]: RegExp } = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
      elo: /^6(?:001|0[0-9]{2}|5[0-9]{2})[0-9]{12}$/,
    };
    for (const flag in cardRegex) {
      if (cardRegex[flag].test(cardNumber)) {
        this.cardIcon = flag;
        return '';
      }
    }
    return '';
  }

  public fillCep(): void{
    const cep = this.formBillingCard.get('billingZipCode')?.value;
    this.dashboardService.cep(cep).subscribe(data => {
      const x = {
        billingAddress: data.logradouro,
        billingCity: data.localidade,
        billingState: data.uf,
        billingNeighborhood: data.bairro,
      };
      console.log(x);
      this.formBillingCard.patchValue(x);
    }, err => {
      console.log(err);
    });
  }

  public post(): void {
    const due = this.formCreditCard.get('due')?.value.toString();
    console.log(due);
    const load = {
        BillingAddress: this.formBillingCard.get('billingAddress')?.value,
        BillingCity: this.formBillingCard.get('billingCity')?.value,
        BillingComplement: this.formBillingCard.get('billingComplement')?.value,
        BillingCpfCnpj: this.formBillingCard.get('billingCpfCnpj')?.value,
        BillingEmail: this.formBillingCard.get('billingEmail')?.value,
        BillingName: this.formBillingCard.get('billingName')?.value,
        BillingNeighborhood: this.formBillingCard.get('billingNeighborhood')?.value,
        BillingNumber: this.formBillingCard.get('billingNumber')?.value,
        BillingPhone: this.formBillingCard.get('billingPhone')?.value,
        BillingState: this.formBillingCard.get('billingState')?.value,
        BillingZipCode: this.formBillingCard.get('billingZipCode')?.value,
        CardVerificationValue: this.formCreditCard.get('cvv')?.value,
        Month: due.substring(0, 2),
        Name: this.formCreditCard.get('holderName')?.value,
        Number: this.formCreditCard.get('number')?.value,
        Year: due.substring(2),
        // Contracts: this.formContract.get('GEN_CONTRATO').value,
    } as CreditCardRequest;
    this.creditCardService.showSpinner();
    this.creditCardService.createCreditCard(load).subscribe(data => {
        this.creditCardService.hideSpinner();
        this.router.navigate(['/principal/cartoes-credito']);
        this.creditCardService.toasterSuccess('Cartão de crédito cadastrado com sucesso!');
    }, err => {
        this.creditCardService.hideSpinner();
        console.log(err);
        this.creditCardService.toasterError('Houve um erro ao criar cartão de crédito');
    });
  }
}
