import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {TemplateModule} from '../template/template.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSliderModule} from '@angular/material/slider';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatCardModule} from '@angular/material/card';
import { RouterModule } from '@angular/router';
import {TableModule} from "@cdsistemas/ui";

@NgModule({
  declarations: [
    DashboardComponent,
  ],
    imports: [
        CommonModule,
        TemplateModule,
        MatGridListModule,
        MatSliderModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatTableModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatCardModule,
        RouterModule,
        TableModule,
    ],
})
export class DashboardModule { }
