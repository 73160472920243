import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BoxAccessUserService } from './box-access-user.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { cpf } from 'cpf-cnpj-validator';
import {GenericDialogComponent} from '../generic-dialog/generic-dialog.component';
import {GenericDialogData} from '../models/GenericDialogData';
import {BoxAccessUsers} from "../models/BoxAccessUsers";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-box-access-user',
  templateUrl: './box-access-user.component.html',
  styleUrls: ['./box-access-user.component.css']
})
export class BoxAccessUserComponent implements OnInit {

  constructor(
    private service: BoxAccessUserService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {
     this.id = this.route.snapshot.params["id"];
     this.contrato = this.route.snapshot.params["codigoContrato"];
     // console.log(this.route.snapshot.params);
     this.mesAbreviado = {
      0: 'Jan',
      1: 'Fev',
      2: 'Mar',
      3: 'Abr',
      4: 'Mai',
      5: 'Jun',
      6: 'Jul',
      7: 'Ago',
      8: 'Set',
      9: 'Out',
      10: 'Nov',
      11: 'Dez'
    };
  }

  public insertUpdateFormBoxAccessUpdate: FormGroup = new FormGroup<any>({});
  public infoUpdateFormBoxAccessUpdate: FormGroup = new FormGroup<any>({});
  private contrato: string = localStorage.getItem('codigo-contrato') ?? "";
  public id: number;
  public mesAbreviado: any;

   public data: BoxAccessUsers = {} as BoxAccessUsers;

  static retornaDiaSemana(data: any): string{
    const diaSemana = new Date(data).getUTCDay();
    switch (diaSemana){
        case 0:
            return 'Domingo';
        case 1:
            return 'Segunda-feira';
        case 2:
            return 'Terça-feira';
        case 3:
            return 'Quarta-feira';
        case 4:
            return 'Quinta-feira';
        case 5:
            return 'Sexta-feira';
        case 6:
            return 'Sábado';
        default:
          return "";
    }
  }

  ngOnInit(): void {
    this.service.showSpinner();
    this.insertUpdateFormBoxAccessUpdate = new FormGroup({
      id: new FormControl(''),
      nome: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required]),
      liberado: new FormControl(''),
      observacao: new FormControl(''),
      nomeDisplay: new FormControl('', [Validators.required]),
      codigoAcesso: new FormControl('', [Validators.required]),
      codigoCadastroGeral: new FormControl(''),
    });

    this.infoUpdateFormBoxAccessUpdate = new FormGroup({
      incluido: new FormControl(''),
      atualizacao: new FormControl('')
    });

    this.insertUpdateFormBoxAccessUpdate.patchValue({
      liberado: true
    });

    if (this.id.toString() !== '0') {
      this.service.getDataBoxAccessUser(this.id.toString()).subscribe((data) => {
        this.data = data;
        this.insertUpdateFormBoxAccessUpdate.patchValue({
          id: data.GEN_CONTRATO_CONTROLE_ACESSO,
          nome: data.NOME.substring(0, 60),
          cpf: data.CPF,
          liberado: data.ACESSO_LIBERADO,
          observacao: data.OBSERVACAO,
          nomeDisplay: data.NOME_DISPLAY.substring(0, 20),
          codigoAcesso: data.CODIGO_ACESSO,
          codigoCadastroGeral: data.CODIGO_CADASTRO_GERAL,

        });
        this.infoUpdateFormBoxAccessUpdate.patchValue({
          incluido: this.dataFormatadaInformacoes(data.INCLUSAO_DATA, data.INCLUSAO_POR),
          atualizacao: this.dataFormatadaInformacoes(data.ALTERACAO_DATA, data.ALTERACAO_POR)
        });

        this.service.hideSpinner();
      }, (err) => {
        this.service.hideSpinner();
        this.service.toasterWarning(err.error);
        this.router.navigate(['principal/dashboard']);
      });

    } else {
      this.infoUpdateFormBoxAccessUpdate.patchValue({
        incluido: this.dataFormatadaInformacoes(new Date(), ''),
        atualizacao: this.dataFormatadaInformacoes(new Date(), '')
      });
      this.service.hideSpinner();
    }
    this.infoUpdateFormBoxAccessUpdate.disable();
  }

  public redirect(uri: string): void {
    this.router.navigate([uri]);
  }

  public setBoxAccessUser(): void {
    const nome = this.insertUpdateFormBoxAccessUpdate.value.nome;
    const cpf = this.insertUpdateFormBoxAccessUpdate.value.cpf;
    const liberado = this.insertUpdateFormBoxAccessUpdate.value.liberado;
    const observacao = this.insertUpdateFormBoxAccessUpdate.value.observacao;
    const nomeDisplay = this.insertUpdateFormBoxAccessUpdate.value.nomeDisplay;
    const codigoAcesso = this.insertUpdateFormBoxAccessUpdate.value.codigoAcesso;
    const codigoCadastroGeral = this.insertUpdateFormBoxAccessUpdate.value.codigoCadastroGeral;
    if (cpf.trim() === '') {
      this.service.toasterWarning('CPF não informado!');
      return;
    }
    if (nome.trim() === '') {
      this.service.toasterWarning('Nome do usuário não informado!');
      return;
    }
    if (nomeDisplay.trim() === '') {
      this.service.toasterWarning('Nome no display não informado!');
      return;
    }
    if (codigoAcesso.trim() === '') {
      this.service.toasterWarning('Código de acesso não informado!');
      return;
    }
    this.gravarUsuarioAcessoBox(cpf, nome, liberado, observacao, nomeDisplay, codigoAcesso, codigoCadastroGeral);
  }

  public gravarUsuarioAcessoBox(cpf: string, nome: string, liberado: boolean , observacao: string, nomeDisplay: string, codigoAcesso: string, codigoCadastroGeral: string): void {
    // const contrato = localStorage.getItem('codigo-contrato');
    // const id = localStorage.getItem('id-box-access-user');
    this.service.showSpinner();
    this.service.setBoxAccessUser(
        this.id.toString(),
        this.contrato,
        cpf,
        nome,
        liberado,
        observacao,
        nomeDisplay,
        codigoAcesso,
        codigoCadastroGeral
    ).subscribe((data) => {
      this.service.hideSpinner();
      if ((this.id) && this.id.toString().trim() !== '0') {
        this.service.toasterSuccess('Usuário alterado com sucesso!');
      } else {
        this.service.toasterSuccess('Usuário criado com sucesso!');
      }
      this.backToContract();
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterWarning(err.error ? 'Erro ao alterar informações do usuário' : 'Erro não especificado');
    });
  }

  loadUser(): void {
    if (!cpf.isValid(this.insertUpdateFormBoxAccessUpdate.value.cpf.trim()) &&
      this.insertUpdateFormBoxAccessUpdate.value.cpf.trim() !== ''
    ) {
      this.service.toasterWarning('CPF Inválido');
      this.insertUpdateFormBoxAccessUpdate.patchValue({
        cpf: ''
      });
    }
    if (this.insertUpdateFormBoxAccessUpdate.value.cpf.trim() !== '' &&
      cpf.isValid(this.insertUpdateFormBoxAccessUpdate.value.cpf.trim())
    ) {
      this.service.showSpinner();
      this.service.getCadastroGeral(this.insertUpdateFormBoxAccessUpdate.value.cpf)
      .subscribe((data) => {
        console.log(data);
        if ((data.NOME) && (data.NOME.length > 0)) {
          this.insertUpdateFormBoxAccessUpdate.patchValue({
            nome: data.NOME.substring(0, 60),
            cpf: data.CPF,
            nomeDisplay: data.NOME.substring(0, 20).toUpperCase(),
            codigoCadastroGeral: data.CODIGO_CADASTRO_GERAL
          });
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      }, (err) => {
        console.error(err);
        this.service.hideSpinner();
        if (err.status !== 404){
          this.service.toasterError(err.error);
        }
      });
    }
  }

  backToContract(): void {
    this.redirect('principal/contrato/' + this.contrato);
  }

  openDialog(): void {
    if(window.confirm("Deseja realmente excluir este usuário?")){
      this.deleteUser();
    }
    // const info = {} as GenericDialogData;
    // info.title = 'Aviso de exclusão';
    // info.msgCloseButton = 'Cancelar';
    // info.msgConfirmButton = 'Confirmar';
    // info.msg = 'Deseja realmente excluir este usuário?<br>Esta ação não poderá ser desfeita.';
    // info.showCloseButton = true;
    // info.isHtmlString = true;
    // const dialog = this.dialog.open(GenericDialogComponent, {
    //   maxWidth: '70%',
    //   autoFocus: true,
    //   role: 'alertdialog',
    //   data: info
    // });
    // dialog.afterClosed().subscribe((data) => {
    //   if (data) {
    //     this.deleteUser();
    //   }
    // });
    /* dialog.afterClosed().subscribe((contracts) => {
      if (contracts) {
        this.service.showSpinner();
        this.service.deleteBoxAccessUser(this.id).subscribe((res) => {
          this.service.toasterSuccess('Usuário excluído');
          this.service.hideSpinner();
          this.router.navigate(['principal/dashboard']);
        }, (err) => {
          this.service.hideSpinner();
          const returnError = {} as GenericDialogData;
          returnError.title = 'Atenção!';
          returnError.msgConfirmButton = 'Ok';
          returnError.msg = err.error;
          returnError.showCloseButton = false;
          const dialogError = this.dialog.open(GenericDialogComponent, {
            maxWidth: '70%',
            autoFocus: true,
            role: 'alertdialog',
            contracts: returnError
          });
        });
      }
    }); */
  }

  deleteUser(): void {
    this.service.showSpinner();
    this.service.deleteBoxAccessUser(this.id).subscribe((data) => {
      if ((this.id) && this.id.toString().trim() === '') {
        this.service.toasterSuccess('Usuário excluído com sucesso!');
      }
      this.backToContract();
      this.service.hideSpinner();
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterWarning(err.error ?? 'Erro ao excluir o usuário');
    });
  }

  getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getAccessCode(): void {
    this.insertUpdateFormBoxAccessUpdate.patchValue({
      codigoAcesso: (('0000000000') + (this.getRandomIntInclusive(9999999, 9999999999).toString())).slice(-10)
    });
  }

  setDisplayName(): void {
    if (this.insertUpdateFormBoxAccessUpdate.value.nome.trim() !== '' ) {
      this.insertUpdateFormBoxAccessUpdate.patchValue({
        nomeDisplay: this.insertUpdateFormBoxAccessUpdate.value.nome.trim().substring(0, 20).toUpperCase()
      });
    }
  }

  dataFormatadaInformacoes(data: Date, usuario: string = '', mesInteiro: boolean = false): string { // Formato: aaaa-mm-dd hh:mm:ss
    if (!data){
        return 'Este registro não foi modificado';
    }

    let informacaoFormatada = '';
    const dia = '';

    informacaoFormatada = this.datePipe.transform(data) ?? "(Data inválida)";
    // informacaoFormatada = this.formatarNumeroString(data.getDate()) + '/';

    // if (mesInteiro){
    //     informacaoFormatada += this.formatarNumeroString(data.getMonth() + 1);
    // } else {
    //     informacaoFormatada += this.mesAbreviado[data.getMonth()];
    // }
    // informacaoFormatada += '/';
    // informacaoFormatada += (data.getFullYear()) + ' - ';
    // informacaoFormatada += BoxAccessUserComponent.retornaDiaSemana(data) + ', às ';
    // informacaoFormatada += this.formatarNumeroString(data.getHours())
    //     + ':'
        // + this.formatarNumeroString(data.getMinutes()) + ':' + this.formatarNumeroString(data.getSeconds());
    if ( usuario && usuario.trim() !== '') {
      informacaoFormatada += ' por ' + usuario;
    } else {
      informacaoFormatada += ' por *Cliente';
    }
    return informacaoFormatada;
  }

  formatarNumeroString = (numero: number): string => {
    if (numero < 9){
        return '0' + String(numero);
    }
    return numero.toString();
  }
}
