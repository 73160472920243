<mat-card>
  <mat-card-header class="title-card-header">
    <mat-icon (click)="returnToDashboard()" style="cursor: pointer">arrow_back</mat-icon>
    <h2>Contratos</h2>
  </mat-card-header>
  <mat-card-content>
    <app-table [dataSource]="dataSourceContractList"
               [displayedColumns]="fieldsContrato"
               defaultEmptyResultMessage="Nenhum contrato encontrado"
               primaryKey="GEN_CONTRATO"
               defaultRoute="/principal/contrato/"
    ></app-table>
  </mat-card-content>
</mat-card>
