import { Observable } from 'rxjs';
import { BaseServiceService } from '../base-service.service';
import { Injectable } from '@angular/core';
import {MyProfile} from '../models/MyProfile';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService extends BaseServiceService {
  public getMyProfile(): Observable<MyProfile> {
    return this.get('client_area/my_profile');
  }
}
