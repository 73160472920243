import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {ContractDetail} from '../models/ContractDetail';
import { BoxAccessUsers } from '../models/BoxAccessUsers';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseServiceService{
  public getContract(id: string): Observable<ContractDetail> {
    return this.get('client_area/contract/' + id);
  }

  public getBoxAccessUsers(id: string): Observable<BoxAccessUsers> {
    return this.get('client_area/box_users_access/' + id);
  }

  public saveMainAccessControl(id: string, data: object): Observable<string> {
    return this.put('client_area/save_main_access_control/' + id, data);
  }
}
