<mat-card>
    <mat-card-header>
      <mat-icon style="cursor: pointer" routerLink="/principal/cartoes-credito">arrow_back</mat-icon>
      <h2>Cadastrar novo cartão de crédito</h2>
    </mat-card-header>
    <mat-card-content>
        <mat-horizontal-stepper [linear]="true">
            <!--<mat-step label="Selecione os contratos">-->
                <!--<form [formGroup]="formContract">-->
                    <!--<div class="row">-->
                        <!--<div class="col-12">-->
                            <!--<mat-form-field>-->
                                <!--<mat-label>Selecione pelo menos um contrato</mat-label>-->
                                <!--<mat-select multiple formControlName="GEN_CONTRATO">-->
                                    <!--<mat-option *ngFor="let contract of contracts" [value]="contract.GEN_CONTRATO">{{'Contrato ' + contract.GEN_CONTRATO + ' (Unidade ' + contract.DESCRICAO_LOJA + ')'}}</mat-option>-->
                                <!--</mat-select>-->
                            <!--</mat-form-field>-->
                        <!--</div>-->
                        <!--<div>-->
                            <!--<button mat-button mat-raised-button matStepperNext color="primary">Próximo</button>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</form>-->
            <!--</mat-step>-->
            <mat-step label="Dados do cartão">
                <form [formGroup]="formCreditCard">
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Número do cartão</mat-label>
                                <input (blur)="getCreditCardFlag()" type="text" matInput required formControlName="number" mask="0000 0000 0000 0000 || 0000 000000 0000">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Nome do titular</mat-label>
                                <input type="text" matInput required formControlName="holderName">
                                <mat-hint>Coloque exatamente como está impresso no cartão</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-6 col-md-2">
                            <mat-form-field>
                                <mat-label>Validade</mat-label>
                                <input matInput required type="text" mask="M0/0000" formControlName="due">
                            </mat-form-field>
                        </div>
                        <div class="col-xs-6 col-md-2">
                            <mat-form-field>
                                <mat-label>CCV</mat-label>
                                <input type="text" required matInput mask="0000" formControlName="cvv">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!--<button mat-button matStepperPrevious>Retornar</button>-->
                            <button mat-button mat-raised-button matStepperNext color="primary">Próximo</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step label="Dados de cobrança do cartão">
                <form [formGroup]="formBillingCard">
                    <div class="row">
                        <div class="col-xs-12 col-md-8">
                            <mat-form-field>
                                <mat-label>Nome</mat-label>
                                <input type="text" matInput formControlName="billingName" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>CPF/CNPJ</mat-label>
                                <input type="text" matInput formControlName="billingCpfCnpj" required mask="CPF_CNPJ">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <mat-form-field>
                                <mat-label>CEP</mat-label>
                                <input (blur)="fillCep()" type="text" matInput formControlName="billingZipCode" required mask="00000-000">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Endereço</mat-label>
                                <input type="text" matInput formControlName="billingAddress" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-2">
                            <mat-form-field>
                                <mat-label>Número</mat-label>
                                <input type="text" matInput formControlName="billingNumber" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Complemento</mat-label>
                                <input type="text" matInput formControlName="billingComplement" required>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Bairro</mat-label>
                                <input type="text" matInput formControlName="billingNeighborhood" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Cidade</mat-label>
                                <input type="text" matInput formControlName="billingCity" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Estado</mat-label>
                                <mat-select formControlName="billingState" required>
                                    <mat-option value=""></mat-option>
                                    <mat-option value="AC">Acre</mat-option>
                                    <mat-option value="AL">Alagoas</mat-option>
                                    <mat-option value="AP">Amapá</mat-option>
                                    <mat-option value="AM">Amazonas</mat-option>
                                    <mat-option value="BA">Bahia</mat-option>
                                    <mat-option value="CE">Ceará</mat-option>
                                    <mat-option value="DF">Distrito Federal</mat-option>
                                    <mat-option value="ES">Espírito Santo</mat-option>
                                    <mat-option value="GO">Goiás</mat-option>
                                    <mat-option value="MA">Maranhão</mat-option>
                                    <mat-option value="MT">Mato Grosso</mat-option>
                                    <mat-option value="MS">Mato Grosso do Sul</mat-option>
                                    <mat-option value="MG">Minas Gerais</mat-option>
                                    <mat-option value="PA">Pará</mat-option>
                                    <mat-option value="PB">Paraíba</mat-option>
                                    <mat-option value="PR">Paraná</mat-option>
                                    <mat-option value="PE">Pernambuco</mat-option>
                                    <mat-option value="PI">Piauí</mat-option>
                                    <mat-option value="RJ">Rio de Janeiro</mat-option>
                                    <mat-option value="RN">Rio Grande do Norte</mat-option>
                                    <mat-option value="RO">Rondônia</mat-option>
                                    <mat-option value="RR">Roraima</mat-option>
                                    <mat-option value="SC">Santa Catarina</mat-option>
                                    <mat-option value="SP">São Paulo</mat-option>
                                    <mat-option value="SE">Sergipe</mat-option>
                                    <mat-option value="TO">Tocantins</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>E-mail</mat-label>
                                <input type="email" matInput formControlName="billingEmail" required>
                            </mat-form-field>
                        </div>
                        <div class="col-xs-12 col-md-4">
                            <mat-form-field>
                                <mat-label>Telefone</mat-label>
                                <input type="text" matInput formControlName="billingPhone" required mask="(00) 00000-0000">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button mat-button matStepperPrevious>Retornar</button>
                            <button mat-button mat-raised-button matStepperNext color="primary">Próximo</button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step label="Finalização e confirmação">
                <!--<mat-card ngbcollapse>-->
                    <!--<mat-card-header>-->
                        <!--<h3>Contratos</h3>-->
                    <!--</mat-card-header>-->
                    <!--<mat-card-content>-->

                    <!--</mat-card-content>-->
                <!--</mat-card>-->
                <!--<mat-card ngbCollapse>-->
                    <!--<mat-card-header>-->
                        <!--<h3>Cartão de crédito</h3>-->
                    <!--</mat-card-header>-->
                    <!--<mat-card-content>-->
                        <!--&lt;!&ndash;<div class="row">&ndash;&gt;-->
                            <!--&lt;!&ndash;<div class="col-xs-12 col-md-4">&ndash;&gt;-->
                                <!--&lt;!&ndash;<span mask="0000 0000 0000 0000">{{formCreditCard.get('number').value}}</span>&ndash;&gt;-->
                                <!--&lt;!&ndash;<span *ngIf="cardIcon"><img class="icon-card" [src]="'/assets/images/cards/' + cardIcon + '.svg'" alt="Bandeira do cartão"></span>&ndash;&gt;-->
                            <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!---->
                    <!--</mat-card-content>-->
                <!--</mat-card>-->
                <div class="row">
                    <div class="col-xs-12">
                        <div class="alert alert-warning">
                            <h2>Antes de prosseguir, leia atentamente os pontos a seguir:</h2>
                            <ol>
                                <li>Confira os dados fornecidos antes de confirmar a operação</li>
                                <li>Ao cadastrar o seu cartão, você concorda que, no dia do vencimento das faturas indicadas acima, serão debitados os valores correspondentes de cada contrato.</li>
                                <li>Caso haja alguma falha no processo de cobrança, o cliente deve realizar o pagamento manualmente até o dia do vencimento sob pena de ser considerado inadimplente</li>
                                <li>Caso deseje cancelar o débito automático no cartão de crédito, basta excluir o cartão na opção "Formas de pagamento" no menu lateral.</li>
                                <ol>
                                    <li>O ato de exclusão do cartão de crédito <strong>NÃO</strong> estorna os valores debitados anteriormente. Apenas impede que novos valores sejam debitados.</li>
                                    <li>Caso haja algum valor que você considere que foi cobrado de forma indevida, por gentileza, entre em contato com o nosso setor financeiro</li>
                                </ol>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button mat-button matStepperPrevious>Retornar</button>
                        <button mat-raised-button mat-button color="primary" (click)="post()">Cadastrar Cartão de Crédito</button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card-content>
</mat-card>
