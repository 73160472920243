import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {InvoiceNew} from '../models/InvoiceNew';
import {Transaction} from '../models/Transaction';
import {CheckoutConfigResponse} from "../models/CheckoutConfigResponse";

@Injectable({
  providedIn: 'root'
})
export class PagamentoFaturaService extends BaseServiceService {

  public getInvoice(id: number): Observable<InvoiceNew> {
    return this.get('client_area/invoice_new/' + id);
  }

  public proccessPayment(id: number, obj: object): Observable<any> {
    return this.post('client_area/proccess_payment/' + id, obj);
  }

  public proccessPaymentCheckout(id: number, paymentType: number): Observable<CheckoutConfigResponse> {
    return this.post('client_area/proccess_payment/' + id + '?payment=' + paymentType, {});
  }
}
