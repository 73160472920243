import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovementComponent } from './movement.component';
import {MatCardModule} from '@angular/material/card';



@NgModule({
  declarations: [MovementComponent],
  imports: [
    CommonModule,
    MatCardModule,
  ]
})
export class MovementModule { }
