import { Router } from '@angular/router';
import { Financial } from './../models/Financial';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { FinancialService } from './financial.service';
import { SafeHtmlPipe } from '../safe-html.pipe';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css']
})

export class FinancialComponent implements OnInit {

  public codeUser = localStorage.getItem('CODIGO_CADASTRO_GERAL');
  public dataSourceFinancial: MatTableDataSource<Financial> = new MatTableDataSource<Financial>();
  public displayedColumnsFinancials: string[];
  public pageOfItems: Array<any> = [];
  public items = [];

  constructor(
    private service: FinancialService,
    public route: Router,
    ) {
      this.displayedColumnsFinancials = [
        'CODIGO_COMPLETO_LANCAMENTO',
        'DATA_EMISSAO',
        'DESCRICAO_LANCAMENTO',
        'DATA_VENCIMENTO',
        'VALOR_PREVISAO',
        'DATA_REAL',
        'VALOR_REAL_FORMATADO',
        'CODIGO_BOLETO',
      ];
    }

  ngOnInit(): void {
    this.service.showSpinner();
    this.service.getFinancialList(this.codeUser ?? "", '0').subscribe((financial) => {
      this.dataSourceFinancial = new MatTableDataSource<Financial>(financial as any);
      this.service.hideSpinner();
    });
  }
  payCard(): void {

  }

  payDebitCard(): void {

  }

  payPix(): void {

  }
}


