import { Injectable } from '@angular/core';
import {BaseServiceService} from '../base-service.service';
import {Observable} from 'rxjs';
import {PasswordReset} from '../models/PasswordReset';

@Injectable({
  providedIn: 'root'
})

export class ForgotPasswordService extends BaseServiceService{
  sendEmailResetPassword(documento: string): Observable<PasswordReset> {
    return this.rawPost(this.getUrl('client_area/send_password_request'), {documento: documento});
  }

  validateCode(documentoToReset: string, stringCode: string): Observable<any>{
    return this.rawPost(this.getUrl('client_area/validate_code'), {documento: documentoToReset, code: stringCode});
  }

  setNewPassword(documentoStr: string, codeStr: string, passwordStr: string, confirmPassword: string): Observable<any> {
    return this.rawPost(this.getUrl('client_area/change_password'),
      {documento: documentoStr, code: codeStr, password: passwordStr, password_2: confirmPassword}
    );
  }

  private rawPost(url: string, body: object): Observable<any>{
    return this.http.post(url, body, {headers: this.getHeaders()});
  }
}
