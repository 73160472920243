import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {CheckoutConfigResponse} from "../models/CheckoutConfigResponse";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InvoiceService} from "../invoice/invoice.service";
import {lastValueFrom, Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {GenericDialogData} from "../models/GenericDialogData";
import {GenericDialogComponent} from "../generic-dialog/generic-dialog.component";

@Component({
  selector: 'app-payment-checkout-modal',
  templateUrl: './payment-checkout-modal.component.html',
  styleUrls: ['./payment-checkout-modal.component.css']
})
export class PaymentCheckoutModalComponent implements OnInit{


  public formCartao = new FormGroup({
    nomeCartao: new FormControl('', [Validators.required]),
    numeroCartao: new FormControl('', [Validators.required]),
    vencimento: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    documento: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    logradouro: new FormControl('', [Validators.required]),
    numero: new FormControl('', [Validators.required]),
    complemento: new FormControl('', []),
    bairro: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    celular: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    cadastrarRecorrencia: new FormControl(false, [])
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public checkoutConfigResponse: CheckoutConfigResponse,
    public invoiceService: InvoiceService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }
  public pdfBoletoSanitizado: SafeResourceUrl = "";
  public pdfBoleto: string = "";
  async ngOnInit(): Promise<any> {
    await this.getBoletoRender();
  }

  public async pagarCartaoCredito(): Promise<any>{
    try {
      this.invoiceService.showSpinner();
      await lastValueFrom(this.invoiceService.payInvoiceWithCreditCard(this.checkoutConfigResponse.IdTransacao.toString(), this.formCartao.getRawValue()));
      this.invoiceService.toasterSuccess("Seu pagamento está sendo processado. Em breve notificaremos sobre a confirmação do pagamento.", "Aguarde")
    } catch (e: any) {
      console.error(e);
      this.invoiceService.toasterError(e.error);
    } finally {
      this.invoiceService.hideSpinner();
    }
  }

  public async preencherEndereco(): Promise<any>{
    try {
      this.invoiceService.showSpinner();
      const cep = this.formCartao.get('cep')?.value ?? "";
      const endereco = await lastValueFrom(this.invoiceService.cep(cep));
      this.formCartao.patchValue({
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.localidade,
        estado: endereco.uf,
      });
    } catch (e) {

    } finally {
      this.invoiceService.hideSpinner();
    }
  }

  public copiarCodigoBarrasBoleto(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.checkoutConfigResponse.BoletoCheckoutResponse?.BarCode ?? "";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.invoiceService.toasterSuccess("Código de barras copiado");
  }

  public copiarQrCodePix(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.checkoutConfigResponse.PixQrCodeResponse?.COPIA_COLA ?? "";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.invoiceService.toasterSuccess("QR Code copiado");
  }

  public async getBoletoRender(): Promise<any>{
    const url = this.invoiceService.getBankInvoiceUrl(this.checkoutConfigResponse.BoletoCheckoutResponse?.Id ?? 0, 1);
    this.pdfBoleto = url;
    this.pdfBoletoSanitizado = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public async redirecionarGateway(){
    // const dialog = await lastValueFrom(this.dialog.open(GenericDialogComponent, {
    //   maxWidth: '60%',
    //   data: {
    //     isHtmlString: true,
    //     msg: 'Você será redirecionado para o nosso gateway de pagamento para finalizar esta operaçāo.',
    //     msgCloseButton: 'Cancelar',
    //     showCloseButton: true,
    //     msgConfirmButton: 'Concordo',
    //     title: 'Aviso de redirecionamento',
    //   } as GenericDialogData,
    //   autoFocus: true,
    //   role: 'alertdialog',
    // }).afterClosed());
    // console.log(dialog);
    // location.href = `${this.checkoutConfigResponse.UrlCheckout}`;
    window.open(this.checkoutConfigResponse.UrlCheckout ?? "", '_blank');
  }

  public async redirecionarBoleto(){
    window.open(this.pdfBoleto ?? "", '_blank');
  }
}
