import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../models/Session';
import {BaseServiceService} from '../base-service.service';
import {AvailableClient} from '../models/AvailableClient';
import {getDatabase} from "../../environments/environment-functions";
import {SystemInfo} from "../models/SystemInfo";


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseServiceService{

  /**
   * Realiza o login do sistema
   * @login login
   * @password password
   */

  login(login: string, pword: string): Observable<string> {
    const load = {
      username: login,
      password: pword
    };
    return this.x(load);
  }

  public getClientsList(): Observable<AvailableClient[]>{
    return this.http.get(this.getUrl('available_clients_list')) as Observable<AvailableClient[]>;
  }

  public getVersionInfo(): Observable<SystemInfo> {
    return this.http.get(this.getUrl('informacoes_sistema')) as Observable<SystemInfo>;
  }

  private x(load: object): Observable<any>{
    return this.http.post(this.getUrl('client_area/login'), load, {headers: {
      'x-database-group': getDatabase(),
    }});
  }
}
