<mat-card>
  <mat-card-header>
    <h1>Lançamentos financeiros em aberto</h1>
  </mat-card-header>
  <mat-card-content>
      <table mat-table [dataSource]="dataSourceOpenedEntries">
        <ng-container matColumnDef="vazio_inicio">
          <th class="vazio" mat-header-cell  *matHeaderCellDef>&nbsp;</th>
          <td class="vazio" mat-cell *matCellDef="let element">&nbsp;</td>
          <td class="vazio" mat-footer-cell *matFooterCellDef>&nbsp;</td>
        </ng-container>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <input type="checkbox" id="check-head"
              (change)="selecionarTodosOsLancamentos()"
              [checked]="hasValue() && isAllSelected()"
              [indeterminate]="hasValue() && !isAllSelected()"
                >
          </th>
          <td mat-cell *matCellDef="let element">
<!--            <input type="checkbox" class="check-entry"-->
<!--                id="{{ element.CODIGO_LANCAMENTO }}"-->
<!--                (click)="$event.stopPropagation()"-->
<!--                (change)="selecionarLancamento(element.CODIGO_LANCAMENTO)"-->
<!--                 data-codigo="{{element.CODIGO_LANCAMENTO}}"-->
<!--                   value="{{element.CODIGO_LANCAMENTO}}"-->
<!--                >-->
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="CODIGO_BOLETO">
          <th mat-header-cell *matHeaderCellDef>Boleto</th>
          <td mat-cell *matCellDef="let element">{{element.CODIGO_BOLETO}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!--<ng-container matColumnDef="CODIGO_COMPLETO_LANCAMENTO">-->
          <!--<th mat-header-cell *matHeaderCellDef>Lançamento</th>-->
          <!--<td mat-cell *matCellDef="let element">{{element.CODIGO_COMPLETO_LANCAMENTO}} </td>-->
          <!--<td mat-footer-cell *matFooterCellDef></td>-->
        <!--</ng-container>-->
        <ng-container matColumnDef="DESCRICAO_LANCAMENTO">
          <th mat-header-cell *matHeaderCellDef>Descrição</th>
          <td mat-cell *matCellDef="let element">{{element.DESCRICAO_LANCAMENTO}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="DATA_EMISSAO">
          <th mat-header-cell *matHeaderCellDef>Emissão</th>
          <td mat-cell *matCellDef="let element">{{element.DATA_EMISSAO | date:"d/MM/y - EEE"}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="DATA_VENCIMENTO">
          <th mat-header-cell *matHeaderCellDef>Vencimento</th>
          <td mat-cell *matCellDef="let element">{{element.DATA_VENCIMENTO | date:"d/MM/y - EEE"}} </td>
          <td class="bold" mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>
        <ng-container matColumnDef="VALOR_PREVISAO">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td class="align-right" mat-cell *matCellDef="let element">{{element.VALOR_PREVISAO | currency: 'BRL'}} </td>
          <td class="align-right bold" mat-footer-cell *matFooterCellDef id="total">{{ 0.00 | currency: 'BRL' }} </td>
        </ng-container>
        <ng-container matColumnDef="DIAS_ATRASO">
          <th class="align-right" mat-header-cell  *matHeaderCellDef>Atraso(dias)</th>
          <td class="align-right" mat-cell *matCellDef="let element">{{element.DIAS_ATRASO}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="vazio">
          <th class="vazio" mat-header-cell  *matHeaderCellDef>&nbsp;</th>
          <td class="vazio" mat-cell *matCellDef="let element">&nbsp;</td>
          <td class="vazio" mat-footer-cell *matFooterCellDef>&nbsp;</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsOpenedEntries"></tr>
        <tr  mat-row *matRowDef="let row; columns: displayedColumnsOpenedEntries;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumnsOpenedEntries; sticky: true"></tr>
      </table>
  </mat-card-content>

  <section>
    <div class="div-btn-footer" align="end">
      <button (click)="payCreditCard()" mat-raised-button>Pagar</button>
    </div>
  </section>
</mat-card>
