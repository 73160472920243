import { Component, OnInit } from '@angular/core';
import { CreditCardContractService } from './credit-card-contract.service';
import {Router} from '@angular/router';
import {CreditCardList} from '../models/CreditCardList';
import {lastValueFrom} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ModalDialogService} from "@cdsistemas/ui";

@Component({
  selector: 'app-credit-card-contract-form',
  templateUrl: './credit-card-contract-form.component.html',
  styleUrls: ['./credit-card-contract-form.component.css']
})
export class CreditCardContractFormComponent implements OnInit {

  constructor(
    private service: CreditCardContractService,
    private matDialogService: ModalDialogService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  public creditCards: Array<CreditCardList> = [];
  public show = false;
  async ngOnInit(): Promise<any> {
    try {
      this.service.showSpinner();
      this.creditCards = await lastValueFrom(this.service.getCreditCards());
      this.show = true;
    } catch (e) {
      console.error(e);
      this.service.toasterError('Houve um erro');
    } finally {
      this.service.hideSpinner();
    }
  }

  public async redirectNew(): Promise<any> {
    await this.router.navigate(['/principal/cadastro-cartao-credito']);
  }

  public async deleteCreditCard(id: string){
    const res = await this.matDialogService.showModalConfirm("Ao excluir este cartão de crédito, você deverá efetuar o pagamento manualmente", "Deseja realmente excluir este cartão de crédito?");
    if(res){
      try {
        this.service.showSpinner();
        await lastValueFrom(this.service.deleteCreditCard(id));
      } catch (e){
        // Nada
      } finally {
        this.service.hideSpinner();
        await this.ngOnInit();
      }
    }
  }

  getLogo(bandeira: string): string{
    return '/assets/images/cards/' + bandeira.toLowerCase() + '.svg';
  }
}
