import { Component, OnInit } from '@angular/core';
import {Dashboard} from '../models/Dashboard';
import {DashboardService} from './dashboard.service';
import {MatTableDataSource} from '@angular/material/table';
import {Contract} from '../models/Contract';
import {Router} from '@angular/router';
import {InvoiceNew} from '../models/InvoiceNew';
import {lastValueFrom} from "rxjs";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {Field, FieldBadge} from "@cdsistemas/ui"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public clientName: string = "";
  public dashboardStatus: Dashboard = {} as Dashboard;
  public dataSourceContracts: MatTableDataSource<Contract> = new MatTableDataSource<Contract>();
  public datasourceMovements: MatTableDataSource<any> = new MatTableDataSource<any>();
  public displayedColumnsContracts: Array<string> = [];

  constructor(
    private service: DashboardService,
    private router: Router,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
  ) {
    this.displayedColumnsContracts = [
      'GEN_CONTRATO',
      'DESCRICAO',
      'DESCRICAO_LOJA',
      'VALOR_BASE',
      'DATA_LOCACAO',
      'FINALIZAR_CONTRATO_DATA',
      'INADIMPLENCIA',
    ];
  }

  async ngOnInit(): Promise<any> {
    await this.getDashboardStatus();
  }

  public fieldsContrato: Field[] = [
    {
      fieldOrder: 1,
      fieldName: "GEN_CONTRATO",
      pipe: (value: any) => value,
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Contrato",
      primaryKey: true,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "DESCRICAO_LOJA",
      pipe: (value: any) => value,
      columnClasses: "",
      visible: true,
      isBadge: false,
      canonicalName: "Unidade",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "DATA_LOCACAO",
      pipe: (value: any) => value ? this.datePipe.transform(value): '(Data indisponível)',
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Data de locação",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    // {
    //   fieldOrder: 1,
    //   fieldName: "FINALIZAR_CONTRATO_DATA",
    //   pipe: (value: any) => value ? this.datePipe.transform(value): '(Sem previsão de encerramento)',
    //   columnClasses: "text-center",
    //   visible: true,
    //   isBadge: false,
    //   canonicalName: "Encerramento",
    //   primaryKey: false,
    //   openRoute: true,
    //   isCheckbox: false,
    // },
    {
      fieldOrder: 1,
      fieldName: "VALOR_BASE",
      pipe: (value: any) => this.currencyPipe.transform(value),
      columnClasses: "text-right",
      visible: true,
      isBadge: false,
      canonicalName: "Valor base",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "SITUACAO",
      pipe: (value: any) => this.currencyPipe.transform(value),
      columnClasses: "text-center",
      visible: true,
      isBadge: true,
      canonicalName: "Situação",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
      badgeConfig: (value: Contract) => {
        let ret: FieldBadge[] = [];
        if(value.INADIMPLENCIA){
          ret.push({
            text: "Inadimplente",
            color: "bg-danger",
          });
        } else {
          ret.push({
            text: "Em dia",
            color: "bg-success",
          });
        }
        return ret;
      }
    },
  ];

  public fieldsFatura: Field[] = [
    {
      fieldOrder: 1,
      fieldName: "CODIGO_FATURA",
      pipe: (value: any) => value,
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Nº",
      primaryKey: true,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "DESCRICAO_FATURA",
      pipe: (value: any) => value,
      columnClasses: "",
      visible: true,
      isBadge: false,
      canonicalName: "Descrição",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "DATA_VENCIMENTO",
      pipe: (value: any) => this.datePipe.transform(value),
      columnClasses: "text-center",
      visible: true,
      isBadge: false,
      canonicalName: "Vencimento",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "VALOR_TOTAL",
      pipe: (value: any) => this.currencyPipe.transform(value),
      columnClasses: "text-right",
      visible: true,
      isBadge: false,
      canonicalName: "Total",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
    {
      fieldOrder: 1,
      fieldName: "SITUACAO",
      pipe: (value: any) => value,
      columnClasses: "text-center",
      visible: true,
      isBadge: true,
      badgeConfig: (value: InvoiceNew) => {
        let ret: FieldBadge[] = [];
        let badge: FieldBadge = {
          text: value.DESCRICAO_STATUS,
          color: value.CODIGO_STATUS == 1 ? "bg-info" : "bg-danger"
        };
        ret.push(badge);
        return ret;
      },
      canonicalName: "Situação",
      primaryKey: false,
      openRoute: true,
      isCheckbox: false,
    },
  ];

  /**
   * Recupera os dados do dashboard
   */
  async getDashboardStatus(): Promise<any> {
      try {
        this.service.showSpinner();
        const data = await lastValueFrom(this.service.getStatistics());
        this.dashboardStatus = data;
        this.dataSourceContracts = new MatTableDataSource<Contract>(data.CONTRATOS);
        this.datasourceMovements = new MatTableDataSource<InvoiceNew>(data.MOVIMENTACOES);
        this.clientName = data.NOME;
        if (data.INADIMPLENTE){
          this.service.toasterWarning('Parece que você tem alguns débitos em aberto. Em caso de dúvidas, procure nosso setor financeiro.', 'Aviso importante');
        }
      } catch (error: any){
        console.error(error);
        this.service.hideSpinner();
        this.service.toasterError(error.error);
      } finally {
        this.service.hideSpinner();
      }
  }

  /**
   * Aplica o filtro de contrato
   */
  applyFilterContract(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContracts.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Aplica o filtro de boletos
   */
  applyFilterInvoice(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourceMovements.filter = filterValue.trim().toLowerCase();
  }

  /**
   *
   */
  async openMovement(id: string): Promise<any> {
    await this.router.navigate(['principal/faturas/' + id]);
  }

  /**
   * Abrir contrato
   */
  async openContract(id: string): Promise<any> {
    await this.router.navigate(['principal/contrato/' + id]);
  }
}
