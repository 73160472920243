import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseServiceService } from '../base-service.service';
import {Contract} from "../models/Contract";

@Injectable({
  providedIn: 'root'
})
export class ContractListService extends BaseServiceService {

  getContractList():Observable<Array<Contract>> {
    return this.get("client_area/contract_list");
  }

}
