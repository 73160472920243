<mat-card id="detalhe_boleto" *ngIf="invoice">
  <mat-card-header>
    <mat-icon style="cursor: pointer">arrow_back</mat-icon>
    <h2>Detalhe do boleto</h2>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-xs-11 col-md-8">
        <p>Referente a:</p>
        <ul>
          <li *ngFor="let l of invoice.LANCAMENTO">
            {{l.DESCRICAO_LANCAMENTO}} <small *ngIf="l.VALOR_PREVISAO">({{l.VALOR_PREVISAO | currency: 'BRL'}})</small>
          </li>
        </ul>
      </div>
      <div class="col-xs-1 col-md-1 offset-md-3">
        <button class="export-buttons float-right" mat-fab color="primary" title="Imprimir" (click)="openInvoice()">
          <mat-icon>print</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col text-center text-black-50 due-info">
        <p class="subtotal-info">Vence em {{invoice.VENCIMENTO | date }}</p>
        <p>{{invoice.LOCAL_PAGAMENTO}}</p>
      </div>
    </div>
    <div class="row" *ngIf="invoice.VENCIDO">
      <div class="col-xs-12 col-md-3 card-subtotal alert-info text-center with-border">
        <p class="subtotal-info">{{invoice.VALOR_DOCUMENTO | currency: 'BRL' }}</p>
        <h3>Subtotal</h3>
      </div>
      <div class="col-xs-12 col-md-1 card-subtotal text-center">
        <p class="subtotal-info">+</p>
      </div>
      <div class="col-xs-12 col-md-3 card-subtotal alert-warning text-center with-border">
        <p class="subtotal-info">{{additionalTax | currency: 'BRL' }}</p>
        <h3>Acréscimos</h3>
        <small>(Juros e multa incluídos neste cálculo)</small>
      </div>
      <div class="col-xs-12 col-md-1 card-subtotal text-center">
        <p class="subtotal-info">=</p>
      </div>
      <div class="col-xs-12 col-md-4 card-subtotal alert-success text-center with-border">
        <p class="subtotal-info">{{invoice.VALOR_DOCUMENTO + invoice.VALOR_ADICIONAL | currency: 'BRL' }}</p>
        <h3>Total a pagar</h3>
      </div>
    </div>
    <div class="row" *ngIf="!invoice.VENCIDO">
      <div class="col-12 card-subtotal alert-success text-center with-border">
        <p class="subtotal-info">{{invoice.VALOR_DOCUMENTO | currency: 'BRL' }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <p class="due-info">
          <span id="barcode">{{barCodeValue}}</span>
          <button class="export-buttons" mat-fab color="default" title="Copiar código de barras" (click)="copyBarCode()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
