<mat-card>
    <mat-card-header>Detalhe da movimentação</mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-12">
                Teste
            </div>
        </div>
    </mat-card-content>
</mat-card>
