import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardContractFormComponent } from './credit-card-contract-form.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {RouterLink} from "@angular/router";



@NgModule({
  declarations: [
    CreditCardContractFormComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        RouterLink,
    ],
  exports:[
    CreditCardContractFormComponent,
  ]
})
export class CreditCardContractFormModule { }
